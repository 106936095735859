<!--  -->
<template>
    <div class='historypage'>
        <div align="center">
            <div id="DomPdf" style="width:1000px;height:1123px;border:1px solid #000000;">
                <div style="margin:45px 57px">
                    <el-row class="title">
                        <el-col :span="6">
                            <div class="blank"></div>
                        </el-col>
                        <el-col :span="12"><span style="text-align: center; display:inline-block; font-size: 24px;">
                                二合一检测报告
                            </span></el-col>
                        <el-col :span="6"><img crossorigin="anonymous" style="width:110px"
                                src="../../../../../public/img/logo_xingou.png" /></el-col>
                    </el-row>
                    <!-- 绘制双横线 -->
                    <hr style="height:2px;border:none;border-top:7px double gray;margin-top: 2px;" />
                    <el-row class="info">
                        <el-col :span="5"><span style="text-align: center; display:inline-block; font-size: 16px;">
                                姓名：{{ realName }}</span></el-col>
                        <el-col :span="5"><span style="text-align: center; display:inline-block; font-size: 16px;">
                                性别：{{ gender === 1 ? '男' : '女' }}</span></el-col>
                        <el-col :span="5"><span style="text-align: center; display:inline-block; font-size: 16px;">
                                年龄：{{age}}</span></el-col>
                        <el-col :span="9"><span style="text-align: center; display:inline-block; font-size: 16px;">
                                时间：{{time}} </span></el-col>
                    </el-row>

                    <div id="myChart1" style="width: 100%;height:300px;"></div>
                    <!-- <div id="myChart2" style="width: 100%;height:400px;"></div> -->
                    <div style="width: 100%;height:100px;">
                        <div align="center">
                            <!-- <div id="myChart" ref="ecg1"
                            :style="'width:' + (screenWidth - 320) + 'px; height:' + 0.34 * (screenWidth - 320) + 'px'"></div> -->
                            <div id="myChart" ref="ecg1"
                            style="width: 100%;height:300px;"></div>
                        </div>
                        <el-dialog title="24小时心电检测报告——心电图" :visible.sync="dialogVisible" :fullscreen="true" center>
                            <div id="myChart2" ref="fullChart" :style="'width:100%;height:' + (screenHeight - 110) + 'px'"></div>
                        </el-dialog>
                        <div style="position:relative; height:70px">
                            <!-- 颜色指示条  -->
                            <div style="position:absolute;z-index:1;transform: translate(-50%);left: 50%;">
                            <canvas id="score_indicate" :width="800" height="30">您的浏览器不支持canvas</canvas>
                            </div>
                            <!-- 时间滑块slider -->
                            <div class="block"
                            :style="'width:800px; position:absolute;z-index:2;transform: translate(-50%);left: 50%;'">
                            <el-slider class="timeslider" v-model="nowIndex" :max="maxIndex" :step="1" :format-tooltip="formatTooltip"
                                :marks="setMarker" show-stops @change="getNewData">
                            </el-slider>
                            <br>
                            <br>
                            <!-- <span class="demonstration">拖动选择时间点</span> -->
                            </div>
                        </div>
                        <div class="indicate_legend">
                        <br>
                        <span class="blank" style="background-color:#F56C6C ">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span style=" "> 心电图严重异常</span>
                        <span style=" ">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span style="background-color:rgb(234 215 79) ">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span style=" "> 疑似度较高</span>
                        <span style=" ">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span style="background-color:#67C23A ">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span style=" "> 健康</span>
                        <span style=" ">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </div>
                    </div>
                </div>
                <!-- <canvas id="ecg" width="793.7px" height="1122.52px" style="border:black 1px solid">您的浏览器不支持canvas</canvas><br> -->
                <!-- <el-button type="primary" plain @click="getPdf('#DomPdf')">下载报告</el-button> -->
            </div>
        </div>
    </div>
</template>
    
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

import { runInThisContext } from 'vm';

export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        //这里存放数据
        return {
            htmlTitle: 'PDF_FHR',
            time: '',
            heartRate: [],
            overRate: 0,
            lowRate: 0,
            normRate: 0,
            header: {},
            realName:'',
            gender:'',
            age:'',
            x: '',
            lowPressure:[],
            highPressure:[],
            dialogVisible: false,
            screenHeight: window.innerHeight,
            screenWidth: window.innerWidth,
            myChart: {},
            createTimeList:[],   // 
            ecgData: [],
            did:"",
            data_rList: [],
            r_num: 0,
            ECG_option: {},
            createTime: "", // 从props中赋值
            nowIndex: 0,
            maxIndex: 0,
        };
    },
    //监听属性 类似于data概念
    computed: {
        setMarker() {
            // 模拟每10个点加标记
            let marks = {};
            var interval_mark = []
            if (this.maxIndex <= 50) {
                interval_mark = 5
            }
            else if (this.maxIndex >= 50 && this.maxIndex < 100) {
                interval_mark = 10
            }
            else if (this.maxIndex >= 100 && this.maxIndex < 400) {
                interval_mark = 40
            } else if (this.maxIndex >= 400 && this.maxIndex < 700) {
                interval_mark = 100
            } else if (this.maxIndex >= 700 && this.maxIndex < 1500) {
                interval_mark = 200
            } else if (this.maxIndex >= 1500) {
                interval_mark = 400
            }
            for (let i = 0; i <= this.maxIndex; i++) {
                if (i % interval_mark == 0) {
                marks[i] = this.createTimeList[i];
                }
            }
            // console.log(this.createTimeList)
            // label的bug在这里，传入最后一个标记点就会报错，但如果手动输入一个字符串，不会报错↓
            // marks[this.maxIndex]="2022-07-07 14:55:24"

            return marks;
            },
    },
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
        formatTooltip(val) {
        // elementui中的滑块组件所使用的函数
        return this.createTimeList[val];
        },
        
        getNewData(e) {
        // console.log(e)
        this.nowIndex = e

        // 从当前位置读取后dataLen个20s的数据，读取个数可以自己根据后端响应情况定义
        this.newTimeList = []
        this.showTimeList = []
        for (let i = 0; i < this.dataLen; i++) {
            if (this.createTimeList[i + e + 1] == undefined) {
            continue
            }
            this.newTimeList[i] = this.createTimeList[i + e + 1]
            this.showTimeList[i] = this.createTimeList[i + e]
        }
        this.getData(this.newTimeList)
        console.log('看看时间轴的刻度点')
        console.log(this.newTimeList)
        },

        canvas_score() {
            var data_len = this.createTimeList.length
            // console.log("长度")
            // console.log(data_len)
            let can = document.getElementById('score_indicate')
            let indicate = can.getContext('2d');
            // 指示条总长度为2000
            for (let i = 0; i < data_len; i++) {
                if (this.score[i] == 0) {
                indicate.strokeStyle = "#67C23A"
                indicate.fillStyle = "#67C23A";
                }
                if (this.score[i] == 50) {
                indicate.strokeStyle = "rgb(234 215 79)"
                indicate.fillStyle = "rgb(234 215 79)";

                }
                if (this.score[i] == 100) {
                indicate.strokeStyle = "#F56C6C"
                indicate.fillStyle = "#F56C6C";

                }
                indicate.lineWidth = 2
                indicate.fillRect((this.screenWidth - 430) / data_len * i, 10, (this.screenWidth - 430) / data_len, 30)    //矩形填充
                indicate.strokeRect((this.screenWidth - 430) / data_len * i, 10, (this.screenWidth - 430) / data_len, 30)  //矩形边框


            }

            },

        ConclusionPageData() {
      var nowConData = []
      var numecg_sum = 0
      this.nowConList = new Array(this.Len_diseaseList)
      for (let i = this.nowIndex; i < this.dataLen + this.nowIndex; i++) {
        if (this.numecg[i + 1] == undefined) {
          continue
        }
        numecg_sum = numecg_sum + this.numecg[i + 1]// 每个元素i是前面所有numecg的和
        nowConData.push(numecg_sum)
      }
      this.nowConData = nowConData   //结论框的横坐标

      this.conclusion_y = Array(this.newTimeList.length).fill(-3);

      // var ConList = [this.xinlv, this.xdgs, this.xdgh, this.xlbq, this.dxtb, this.fxzb, this.jjxzb, this.sxzb, this.ssxxdgs, this.sxxdgs, this.fxyb, this.sxyb, this.fc, this.qtyc, this.method]
      for (let k = 0; k < this.Len_diseaseList; k++) {
        var nowConList = []
        for (let i = this.nowIndex; i < this.dataLen + this.nowIndex; i++) {
          if (this.ConList[k][i] == undefined) {
            continue
          }
          nowConList.push(this.ConList[k][i])
        }
        this.nowConList[k] = nowConList   //结论14项项目内容+method
      }
      this.nowConList.push(this.nowConData, this.conclusion_y, this.newTimeList, this.ecgdeviceList)
      console.log(this.nowConList)
    },
        //结论参数
        _disease(name, unit, result) {
        this.name = name; //疾病名称
        this.result = result
        this.unit = unit; //单位
        },
        getData(timeList) {
            var filter_timeList = timeList.filter(function (s) {
                return s && s.trim();
            })
            let type = '1291';
            if (this.ecgid === '1') {
                type = '101';
            }
            if (this.$store.state.module.crossPlatform) {
                console.log("object11111");
                this.header = { "Cross-Platform-Verification": "Cross-Platform-Access" }
            }
            this.$http.adornHeader(this.header)
            // 传入一个时间数组，返回该数组中所列时间的拼接后数据
            this.$http({
                url: this.$http.adornUrl('/personal/dynamicecg/getBatchData'),
                method: 'post',
                data: this.$http.adornData({
                did: this.did,
                startTime: filter_timeList,
                type: type
                }),
            }).then(({ data }) => {
                if (data && data.code === 0) {
                this.ecgData = data.dynamicEcgData.split(",")
                this.ecgData = this.ecgData.map(function (index) {
                    return (index * 0.0360608 / 240000);      //* 0.0360608 / 240000表示mV信号的转换
                });
                this.UpdateECGChart()
                // TODO: 获取到数据，然后开始绘制
                } else {
                this.$message.error(data.msg)
                }
            })
        },
        findNearestTime(timeArray, t0) {
            let index = 1
            var nearestTime = timeArray[1];
            var timeDiff = Math.abs(new Date(t0) - new Date(nearestTime));

            for (var i = 1; i < timeArray.length; i++) {
                var currTime = timeArray[i];
                var currDiff = Math.abs(new Date(t0) - new Date(currTime));

                if (currDiff < timeDiff) {
                    nearestTime = currTime;
                    timeDiff = currDiff;
                    index = i;
                    this.nowIndex = i
                }
            }
            let startIndex = Math.max(1, index - 6);
            let endIndex = Math.min(timeArray.length, index + 6)

            let selectTime = []
            for(let i = startIndex; i < endIndex; i++){
                selectTime.push(timeArray[i]);
            }
            return selectTime;
        },
        getConclusion() {
            if (this.$store.state.module.crossPlatform) {
                console.log("object11111");
                this.header = { "Cross-Platform-Verification": "Cross-Platform-Access" }
            }
            this.$http.adornHeader(this.header)
            console.log("sblzy", this.did);
            // 根据每条心电记录的did获取该条的结论
            this.$http({
                url: this.$http.adornUrl('/personal/dynamicecg/getConclusion'),
                method: 'get',
                params: this.$http.adornParams({ did: this.did })
            }).then(({ data }) => {
                if (data && data.code === 0) {
                // console.log("看看数据")
                // console.log(data)

                this.conclusionData = data.conclusionData
                // 获取到结论后，判断是新还是老的存储形式,新的就处理一下
                if (this.conclusionData[this.conclusionData.length - 1] == ',') {
                    this.conclusionData = "[" + this.conclusionData.substring(0, this.conclusionData.length - 1) + "]";
                }

                // 将20s一条的结论转换为对象数组，然后提取出所有记录的createTime
                this.conclusionData = JSON.parse(this.conclusionData)

                this.ecgid = this.conclusionData[0].ecgid
                // console.log(this.ecgid)
                // this.tlist = this.conclusionData[0].tlist
                // this.tlist = this.tlist.split(',')


                //结论框中的项目，以对象列表形式存储
                var diseaseList = {
                    "xinlv": new this._disease("心率", "次/分钟", ""),
                    "xdgs": new this._disease("窦性心动过速", "%", ""),
                    "xdgh": new this._disease("窦性心动过缓", "%", ""),
                    "xlbq": new this._disease("窦性心律不齐", "%", ""),
                    "fxzb": new this._disease("房性早搏", "%", ""),
                    "jjxzb": new this._disease("交界性早搏", "%", ""),
                    "sxzb": new this._disease("室性早搏", "%", ""),
                    "ssxxdgs": new this._disease("室上性心动过速", "%", ""),
                    "sxxdgs": new this._disease("室性心动过速", "%", ""),
                    "fxyb": new this._disease("交界性逸搏", "%", ""),
                    "sxyb": new this._disease("室性逸搏", "%", ""),
                    "fc": new this._disease("房颤", "%", ""),
                    "qtyc": new this._disease("心梗相关疾病", "", ""),
                    "method": new this._disease("模型方法", "", ""),
                    // "grb":"干扰波"//判断是否是干扰波 0:正常 1:干扰波
                };
                this.Len_diseaseList = Object.keys(diseaseList).length //疾病结论的长度

                this.tlist = new Array(this.conclusionData.length)
                this.plist = new Array(this.conclusionData.length)
                this.qlist = new Array(this.conclusionData.length)
                this.rlist = new Array(this.conclusionData.length)
                this.slist = new Array(this.conclusionData.length)
                this.s2list = new Array(this.conclusionData.length)
                //新增T1
                this.t1list = new Array(this.conclusionData.length)

                this.numecg = new Array(this.conclusionData.length)
                this.numecg[0] = 0
                this.score = new Array(this.conclusionData.length)
                var endTimeList = new Array(this.conclusionData.length)
                this.ecgdeviceList = new Array(this.conclusionData.length)

                //结论列表
                this.ConList = new Array(this.Len_diseaseList)
                for (let m = 0; m < this.Len_diseaseList; m++) {
                    this.ConList[m] = new Array(this.conclusionData.length)

                }

                for (let i = 0; i < this.conclusionData.length; i++) {
                    endTimeList[i] = this.conclusionData[i].time  //直接接收到的其实是每一段的结束时间

                    // 标志点赋值
                    this.tlist[i] = this.conclusionData[i].tlist.split(',')
                    this.plist[i] = this.conclusionData[i].plist.split(',')
                    this.qlist[i] = this.conclusionData[i].qlist.split(',')
                    this.rlist[i] = this.conclusionData[i].rlist.split(',')
                    this.slist[i] = this.conclusionData[i].slist.split(',')
                    this.s2list[i] = this.conclusionData[i].s2list.split(',')

                    if (this.conclusionData[i].t1list !== undefined && this.conclusionData[i].t1list !== "") {
                    this.t1list[i] = this.conclusionData[i].t1list.split(',')
                    }
                    // 给结论列表赋值
                    for (let m = 0; m < 14; m++) {
                    this.ConList[m][i] = this.conclusionData[i][Object.keys(diseaseList)[m]]
                    }
                    this.numecg[i + 1] = this.conclusionData[i].numecg
                    this.score[i] = this.conclusionData[i].score


                    // 计算散点图数据 （有误差算法）
                    let r_count = this.rlist[i].length
                    for (let r_ind = 1; r_ind < r_count - 2; r_ind++) {
                    let sca_coor = new Array(2)
                    sca_coor[0] = (this.rlist[i][r_ind] - this.rlist[i][r_ind - 1]) * 1000 / 500
                    sca_coor[1] = (this.rlist[i][r_ind + 1] - this.rlist[i][r_ind]) * 1000 / 500
                    this.r_num += 1
                    this.data_rList.push(sca_coor)
                    }

                    // 设备类型-列表
                    if (this.conclusionData[i].ecgdevice == undefined) {
                    this.ecgdeviceList[i] = 'Android'
                    } else {
                    this.ecgdeviceList[i] = 'iOS'
                    }

                }
                console.log(this.ecgdeviceList)
                var createTimeList = [this.createTime].concat(endTimeList)
                // console.log(this.tlist)

                this.createTimeList = createTimeList
                this.maxIndex = this.createTimeList.length - 2 //加入了初始时间，所有最后一个结束时间也没有用到了，结束时间只做查询用
                // 获取到所有的createTime后，取前9个20秒(3分钟)作为初始数据 
                this.dataLen = 6
                console.log(this.createTimeList);
                console.log("this.createTimeList");
                this.newTimeList = this.createTimeList.slice(1, this.dataLen + 1)
                this.showTimeList = this.createTimeList.slice(0, this.dataLen)
                
                this.nowIndex = 0
                this.canvas_score();
                // this.ScatterChart(document.getElementById("Scatter"))
                this.getData(this.newTimeList)
                // TODO: 每个20s的红黄蓝标记后面新的数据中会专门存这个结论，到时候直接调用
                // this.canvas_score();
                } else {
                // this.getCaptcha()
                this.$message.error(data.msg)
                }
            })
            },
        initECGChart(domName) {
            this.myChart = this.$echarts.init(domName);
            this.myChart.showLoading({
                text: '请稍等, 正在为您加载数据',
                zlevel: 0,
                // 字体大小。
                fontSize: 20,
                // 是否显示旋转动画（spinner）。
                showSpinner: true,
                // 旋转动画（spinner）的半径。
                spinnerRadius: 16,
                // 旋转动画（spinner）的线宽。
                lineWidth: 8,
            })
            // 基于刚刚准备好的 DOM 容器，初始化 EChart 实例
        },
        initECGChart1(domName) {
            this.myChart1 = this.$echarts.init(domName);
            // this.myChart = this.$echarts.init(domName);
            
            // 基于刚刚准备好的 DOM 容器，初始化 EChart 实例
        },
        UpdateECGChart() {
            this.x = [];
            for (let i = 0; i < this.ecgData.length; i++) {
                this.x.push(i);
            }

            // 心电图数据
            this.ECG_option.xAxis[0].max = []
            this.ECG_option.dataset[0].source = []
            this.ECG_option.xAxis[0].max = this.ecgData.length
            this.ECG_option.dataset[0].source = [this.x, this.ecgData]
            // 更新ECG-ID
            this.ECG_option.graphic[0].children[3].style.text = "HWD " + this.ecgid
            // this.DrawMarkPoint()
            this.ConclusionPageData()
            // console.log(this.newTimeList)
            // datazoom滑块左右两侧时间刻度
            var base = +new Date(this.showTimeList[0].substring(0, 4),
                this.showTimeList[0].substring(5, 7) - 1,
                this.showTimeList[0].substring(8, 10),
                this.showTimeList[0].substring(11, 13),
                this.showTimeList[0].substring(14, 16),
                this.showTimeList[0].substring(17, 19));
            // TODO: 每个20s的红黄蓝标记后面新的数据中会专门存这个结论，到时候直接调用

            // 横轴坐标
            this.ECG_option.dataZoom.labelFormatter = []
            this.ECG_option.dataset[1].source = []
            this.ECG_option.dataset[2].source = []
            this.ECG_option.dataset[3].source = []
            this.ECG_option.dataset[4].source = []
            this.ECG_option.dataset[5].source = []
            this.ECG_option.dataset[6].source = []
            this.ECG_option.dataset[7].source = []

            this.ECG_option.dataZoom.labelFormatter = function (value) {
                let date = new Date(base + value * 2)  // 2ms一个点
                let y = date.getFullYear();
                let MM = date.getMonth() + 1;
                MM = MM < 10 ? ('0' + MM) : MM;
                let d = date.getDate();
                d = d < 10 ? ('0' + d) : d;
                let h = date.getHours();
                h = h < 10 ? ('0' + h) : h;
                let m = date.getMinutes();
                m = m < 10 ? ('0' + m) : m;
                let s = date.getSeconds();
                s = s < 10 ? ('0' + s) : s;
                let ss = date.getMilliseconds();
                ss = ss < 10 ? ('00' + ss) : ss;
                ss = (ss > 10 && ss < 100) ? ('0' + ss) : ss;
                // return  MM + '-' + d + ' ' + h + ':' + m + ':' + s+'.'+ss;   //显示到毫秒
                return y + '-' + MM + '-' + d + '\n' + h + ':' + m + ':' + s;  //显示到秒
            },

            // 结论数据（包含疾病项目、时间、方法）
                this.ECG_option.dataset[1].source = this.nowConList

            // // 标志点数据
            // this.ECG_option.dataset[2].source = [
            //     this.markpoints_x[0],
            //     this.markpoints_y[0]
            // ]
            // this.ECG_option.dataset[3].source = [
            //     this.markpoints_x[1],
            //     this.markpoints_y[1]
            // ]
            // this.ECG_option.dataset[4].source = [
            //     this.markpoints_x[2],
            //     this.markpoints_y[2]
            // ]
            // this.ECG_option.dataset[5].source = [
            //     this.markpoints_x[3],
            //     this.markpoints_y[3]
            // ]
            // this.ECG_option.dataset[6].source = [
            //     this.markpoints_x[4],
            //     this.markpoints_y[4]
            // ]
            // this.ECG_option.dataset[7].source = [
            //     this.markpoints_x[5],
            //     this.markpoints_y[5]
            // ]
            // if (this.markpoints_x[6].length > 0) {
            //     this.ECG_option.dataset[8].source = [
            //     this.markpoints_x[6],
            //     this.markpoints_y[6]
            //     ]
            // }

            this.myChart.hideLoading();
            this.myChart.setOption(this.ECG_option);

            window.addEventListener("resize", () => {  //根据屏幕进行实时绘制
                this.myChart.resize();
            });
            },
        initPieChart(domName) {
            // this.myChart2 = this.$echarts.init(domName);
            // 基于刚刚准备好的 DOM 容器，初始化 EChart 实例
        },
        Babychart() {
            console.log("object123123123123123");
            console.log(this.heartRate);
            this.option = {
                xAxis: {
                    type: 'time', // 将类型更改为 'time'
                    name: '时间',
                },
                yAxis: [
                    {
                        name: '血压',
                        type: 'value',
                    },
                    {
                        name: '心率',
                        alignTicks: true,
                        type: 'value',
                    }
                ],
                series: [
                    {
                        name: '高压',
                        type: 'line',
                        data: this.highPressure.map((value, index) => [this.x[index], value])
                    },
                    {
                        name: '低压',
                        type: 'line',
                        data: this.lowPressure.map((value, index) => [this.x[index], value])
                    },
                    {
                        name: '心率',
                        type: 'line',
                        yAxisIndex: 1,
                        data: this.heartRate.map((value, index) => [this.x[index], value])
                    }
                ],
                tooltip: {
                    trigger: 'axis',
                    formatter: function (params) {
                        let result = '';
                        params.forEach(param => {
                            result += `${param.marker} ${param.seriesName}: ${param.value[1]}<br/>`;
                        });
                        return result;
                    }
                },
                legend: {
                    // 添加图例相关设置（如果需要）
                }
            };
            this.myChart1.setOption(this.option);
            this.myChart1.on('click', params => {
                console.log("LZYSSB");
                console.log(params);  // 打印你点击的数据
                this.getECGbyBP(params.value[0]);
        });
        },
        totalLine(){
            this.option = {
                title: {
                    text: '二合一检测结果',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                    animation: false
                    }
                },
                legend: {
                    data: ['血压', '心率'],
                    left: 10
                },
                toolbox: {
                    feature: {
                    dataZoom: {
                        yAxisIndex: 'none'
                    },
                    restore: {},
                    saveAsImage: {}
                    }
                },
                axisPointer: {
                    link: [
                    {
                        xAxisIndex: 'all'
                    }
                    ]
                },
                dataZoom: [
                    {
                    show: true,
                    realtime: true,
                    start: 30,
                    end: 70,
                    xAxisIndex: [0, 1]
                    },
                    {
                    type: 'inside',
                    realtime: true,
                    start: 30,
                    end: 70,
                    xAxisIndex: [0, 1]
                    }
                ],
                grid: [
                    {
                    left: 60,
                    right: 50,
                    height: '35%'
                    },
                    {
                    left: 60,
                    right: 50,
                    top: '55%',
                    height: '35%'
                    }
                ],
                xAxis: [
                    {
                    type: 'category',
                    boundaryGap: false,
                    axisLine: { onZero: true },
                    data: this.x
                    },
                    {
                    gridIndex: 1,
                    type: 'category',
                    boundaryGap: false,
                    axisLine: { onZero: true },
                    data: this.x,
                    position: 'top'
                    }
                ],
                yAxis: [
                    {
                    name: '血压',
                    type: 'value',
                    max: 200
                    },
                    {
                    gridIndex: 1,
                    name: '心率',
                    type: 'value',
                    inverse: true
                    }
                ],
                series: [
                    {
                    name: '高压',
                    type: 'line',
                    symbolSize: 8,
                    // prettier-ignore
                    data: this.highPressure
                    },
                    {
                    name: '低压',
                    type: 'line',
                    symbolSize: 8,
                    // prettier-ignore
                    data: this.lowPressure
                    },
                    {
                    name: '心率',
                    type: 'line',
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    symbolSize: 8,
                    // prettier-ignore
                    data: this.heartRate
                    }
                ]
                };
            // this.myChart2.setOption(this.option);
        },
        getinfo() {
            this.$http.adornHeader(this.header)
            if (this.$store.state.module.crossPlatform) {
                this.header = { "Cross-Platform-Verification": "Cross-Platform-Access" }
            }
            this.$http({
                url: this.$http.adornUrl('/personal/info/getBasicInfo'),
                method: 'get',
                // headers: this.$http.adornHeader(this.header), 
                params: this.$http.adornParams({ uid: this.uid })
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    console.log(data)
                    this.gender = data.gender;
                    this.age = data.age;
                    this.realName = data.realName
                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg)
                }
            })
        },
        getTwoInOneData(){
            this.$http({
            url: this.$http.adornUrl("/personal/personaldynamicbp/getBPRecord"),
            method: "post",
            params: this.$http.adornParams({ id: this.id }),
        }).then(({ data }) => {
            if (data && data.code === 0) {
            this.loadingShow = false
            this.datalist = data.conclusions;
            const xinlvArray = this.datalist.map(item => item.xinlv);
            const highPressureArray = this.datalist.map(item => item.highpressure);
            const lowPressureArray = this.datalist.map(item => item.lowpressure);
            const xLabels = this.datalist.map(item => item.time);
            // xLabels = xLabels.map(strTime => new Date(strTime));
            this.heartRate = xinlvArray
            this.x = xLabels
            this.lowPressure = lowPressureArray
            this.highPressure = highPressureArray
            this.Babychart()
            // this.totalLine()
            } else {
            // this.getCaptcha()
            this.$message.error(data.msg);
            }
        });
        },
        getECGbyBP(data){
            // 获取当前点的数据，调用数据
            let time_list = this.findNearestTime(this.createTimeList, data);
            this.showTimeList = time_list
            this.newTimeList = time_list
            console.log(this.showTimeList);
            console.log("this.showTimeList");
            this.getData(time_list)
            this.myChart.showLoading({
                text: '请稍等, 正在为您加载数据',
                zlevel: 0,
                // 字体大小。
                fontSize: 20,
                // 是否显示旋转动画（spinner）。
                showSpinner: true,
                // 旋转动画（spinner）的半径。
                spinnerRadius: 16,
                // 旋转动画（spinner）的线宽。
                lineWidth: 8,
            })
        },
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.time = this.$route.query.RecordCreatetime
        this.uid = this.$route.query.uid
        this.id = this.$route.query.id
        if (this.$route.query.did != null) {
            this.did = this.$route.query.did
        }else{
            this.did = 0
        }
        this.createTime = this.$route.query.RecordCreatetime
        this.type_detect = this.$route.query.type_detect
        this.totalTime = this.$route.query.totalTime
        console.log("看看数据123", this.did);
        this.ECG_option = {
        // echarts工具栏
        animation: false,
        toolbox: {
            feature: {
            dataZoom: { yAxisIndex: "none", show: false }, // 数据区域缩放
            restore: { show: true }, // 重置
            saveAsImage: { show: true }, // 导出图片
            // 全屏
            myFull: {
                show: true,
                title: "全屏显示",

                icon: "path://M85.333333 682.666667v128a128 128 0 0 0 128 128h128a42.666667 42.666667 0 0 0 0-85.333334H213.333333a42.666667 42.666667 0 0 1-42.666666-42.666666v-128a42.666667 42.666667 0 0 0-85.333334 0z m597.333334 256h128a128 128 0 0 0 128-128v-128a42.666667 42.666667 0 0 0-85.333334 0v128a42.666667 42.666667 0 0 1-42.666666 42.666666h-128a42.666667 42.666667 0 0 0 0 85.333334z m256-597.333334V213.333333a128 128 0 0 0-128-128h-128a42.666667 42.666667 0 0 0 0 85.333334h128a42.666667 42.666667 0 0 1 42.666666 42.666666v128a42.666667 42.666667 0 0 0 85.333334 0zM341.333333 85.333333H213.333333a128 128 0 0 0-128 128v128a42.666667 42.666667 0 0 0 85.333334 0V213.333333a42.666667 42.666667 0 0 1 42.666666-42.666666h128a42.666667 42.666667 0 0 0 0-85.333334z",
                onclick: () => {
                // 全屏查看
                this.dialogVisible = true; // 打开弹窗
                this.$nextTick(() => {

                    const chartFul = this.$refs.fullChart;
                    if (chartFul) {
                    this.initECGChart(document.getElementById("myChart2")); // 画图
                    this.UpdateECGChart()

                    }
                });
                },
            },

            },
            right: "8%",
            top: "0%",
        },

        grid: {
            left: 55 /*"50px"*/,
            right: 60 /*"15px"*/,
            top: 25 /*"15px"*/,
            bottom: 40 /*"15px"*/,
            borderWidth: "0",
        },
        legend: {
            selected: {
            "显示/隐藏标志点": false
            },
            itemGap: 30,
            inactiveColor: '#444',
            textStyle: {
            fontSize: 16
            }
        },
        xAxis: [
            {
            type: "value",
            interval: 100,
            boundaryGap: false,
            show: true,
            max: [],  //限制横坐标的长度
            splitLine: {
                show: true,
                lineStyle: {
                // color: ["#e03433", "#FFB6C1", "##FFB6C1", "#FFB6C1", "#FFB6C1"],
                color: "#e03433"
                },
            },
            minorSplitLine: {
                show: true,
                lineStyle: {
                // color: ["#e03433", "#FFB6C1", "##FFB6C1", "#FFB6C1", "#FFB6C1"],
                color: "#FFB6C1"
                },
            },
            axisTick: {
                show: false,
            },
            axisLabel: {
                show: false,
            },
            axisLine: {
                show: false,
            }
            },
            {
            type: "value",
            max: 100,
            splitLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
            axisLabel: {
                show: false,
            },
            axisLine: {
                show: false,
            }
            }],
        yAxis: {
            type: "value",
            interval: 0.5,
            boundaryGap: false,
            splitLine: {
            show: true,
            lineStyle: {
                // color: ["#e03433", "#FFB6C1", "##FFB6C1", "#FFB6C1", "#FFB6C1"],
                color: "#e03433"
            },
            },
            minorSplitLine: {
            show: true,
            lineStyle: {
                // color: ["#e03433", "#FFB6C1", "##FFB6C1", "#FFB6C1", "#FFB6C1"],
                color: "#FFB6C1"
            },
            },

            max: 2.5, //设置最大值
            min: -5.5, //最小值
            axisTick: {
            show: false,
            },
            axisLabel: {
            show: false,
            },
        },
        dataZoom: {
            type: "slider",
            show: true,
            filterMode: 'none',

            labelFormatter: '',
            xAxisIndex: [0],
            startValue: 0,
            endValue: 5000, //初始化滚动条为10s
            textStyle: {
            show: false,
            fontSize: 14,
            color: '#000',
            fontFamily: 'Arial'
            },
            backgroundColor: "#fff",
            showDetail: true, //即拖拽时候是否显示详细数值信息 默认true
            // 控制拖动条最大最小值，现设为固定10s
            zoomlock: true,
            brushSelect: false,
            maxValueSpan: 5000,
            minValueSpan: 5000,
        },
        graphic: [
            {
            type: 'group',
            bounding: 'raw',
            top: (this.screenWidth) * 0.02,
            left: 20 + (this.screenWidth) * 0.010,
            z: 100,
            children: [
                {
                type: 'text',
                left: 80,
                z: 100,
                style: {
                    fill: '#000',
                    text: '5mm',
                    font: ' 16px sans-serif'
                }
                },
                // {
                //   type: 'line',
                //   left: 60 + (this.screenWidth) * 0.012,
                //   z: 100,
                //   shape: {
                //     x1: 0,
                //     y1: 16,
                //     x2: (this.screenWidth) * 0.014,
                //     y2: 16,
                //   },
                //   style: {
                //     fill: '#000',
                //     lineWidth: 3
                //   }
                // },
                {
                type: 'text',
                left: 150,
                z: 100,
                style: {
                    fill: '#000',
                    text: '增益10mm/mv',
                    font: ' 16px sans-serif'
                }
                },
                {
                type: 'text',
                left: 300,
                z: 100,
                style: {
                    fill: '#000',
                    text: '走速25mm/s',
                    font: ' 16px sans-serif'
                }
                },
                {
                type: 'text',
                left: 500,
                z: 100,
                style: {
                    fill: '#000',
                    text: 'HWD-ID 2103020017',
                    font: ' 16px sans-serif'
                }
                }
            ]
            },
        ],
        dataset: [
            {
            // 序号为 0 的 dataset：心电图
            source: [

            ]
            },
            {
            // 序号为 1 的 dataset:结论
            source: [
            ]
            },
            // 下面的数据集用来展示标志点，序号以此类推
            // 2
            {
            source: [
            ]
            },
            //3
            {
            source: [
            ]
            },
            //4
            {
            source: [
            ]
            },
            //5
            {
            source: [
            ]
            },
            //6
            {
            source: [
            ]
            },
            //7
            {
            source: [
            ]
            },
            //8 心电图比例尺
            {
            source: [
                [6, 8],
                [2, 2]
            ]
            }
        ],
        series: [
            {
            xAxisIndex: 0,
            datasetIndex: 0,
            symbol: "none" /*去掉小圆点*/,
            name: '心电图',
            type: "line",
            // encode: { x: 0, y: 1 },
            seriesLayoutBy: "row",
            itemStyle: {
                normal: {
                lineStyle: {
                    color: "#000" /*折线的颜色*/,
                    width: 1.5
                },
                color: "#000" /*图例(legend)的颜色,不是图例说明文字的颜色*/,
                },
            },
            tooltip: {
                trigger: 'item',
            }
            },
            {
            xAxisIndex: 0,
            datasetIndex: 1,
            name: '结论',
            type: "scatter",
            symbol: 'roundRect',
            symbolSize: 1,
            itemStyle: {
                borderColor: 'rgb(199,86,83)',
                borderWidth: 1,
                color: '#fff',
            },
            encode: { x: 14, y: 15 },
            seriesLayoutBy: "row",
            label: {
                normal: {
                position: 'left',
                show: true,
                formatter: function (e) {
                    // console.log(e.value)
                    var value = e.value
                    // var dxtb_flag = '无'
                    // if (value[6] > 0) {
                    //   dxtb_flag = '有'
                    // }
                    var qtycmap = { 0: "ST-T正常", 1: "T波倒置", 2: "ST段抬高", 3: "ST段压低" }//心梗相关疾病的map
                    var methodmap = { 'rule': "规则模型", 'dl': "深度学习模型" }
                    var content =
                    `{term1|心率：${value[0]}次/分钟}{term2|}\n` +
                    `{term1|窦性心动过速疑似度：${value[1]}%}{term2|室上性心动过速疑似度：${value[7]}%}\n` +
                    `{term1|窦性心动过缓疑似度：${value[2]}%}{term2|室性心动过速疑似度：${value[8]}%}\n` +
                    `{term1|窦性心律不齐疑似度：${value[3]}%}{term2|交界性逸博疑似度：${value[9]}%}\n` +
                    `{term1|房性早搏疑似度：${value[4]}%}{term2|室性逸搏疑似度：${value[10]}%}\n` +
                    `{term1|交界性早搏疑似度：${value[5]}%}{term2|房颤疑似度：${value[11]}%}\n` +
                    `{term1|室性早搏疑似度：${value[6]}%}{term2|心梗相关疾病：${qtycmap[value[12]]}}\n` +
                    `{fragment1|时间：${value[16]}}{blank|}{fragment2|${methodmap[value[13]]}}{blank|}{fragment3|${value[17]}}`
                    return content
                },
                backgroundColor: 'rgb(255 255 255 / 90%)',
                // borderColor: '#333',
                borderColor: 'rgb(199,86,83)',
                borderWidth: 2,
                borderRadius: 5,
                padding: 10,
                color: '#000',
                shadowBlur: 3,
                shadowColor: '#cfcfcf',
                shadowOffsetX: 0,
                shadowOffsetY: 3,
                lineHeight: 19,
                rich: {
                    term1: {
                    fontSize: 15,
                    width: 200
                    },
                    term2: {
                    fontSize: 15,
                    width: 190
                    },
                    fragment1: {
                    align: 'left',
                    fontSize: 14,
                    lineHeight: 25,
                    backgroundColor: '#696969',
                    color: '#fff',
                    borderRadius: 8,
                    padding: 5
                    },
                    fragment2: {
                    align: 'left',
                    fontSize: 14,
                    lineHeight: 25,
                    backgroundColor: '#428bca',
                    color: '#fff',
                    borderRadius: 8,
                    padding: 5
                    },
                    fragment3: {
                    align: 'left',
                    fontSize: 14,
                    lineHeight: 25,
                    backgroundColor: '#DC9E7B',
                    color: '#fff',
                    borderRadius: 8,
                    padding: 5
                    },
                    blank:
                    {
                    width: 10
                    }
                }
                }
            }
            },
            {
            xAxisIndex: 0,
            datasetIndex: 2,
            type: "scatter",
            seriesLayoutBy: "row",
            name: '显示/隐藏标志点',
            z: 3,
            symbolSize: 7,
            itemStyle: {
                borderColor: "#0652DD",
                borderWidth: 2,
                color: '#f56c6c00',
            },
            },
            {
            xAxisIndex: 0,
            datasetIndex: 3,
            type: "scatter",
            seriesLayoutBy: "row",
            name: '显示/隐藏标志点',
            z: 3,
            symbolSize: 7,
            itemStyle: {
                borderColor: "#EE5A24",
                borderWidth: 2,
                color: '#f56c6c00',
            },
            },
            {
            xAxisIndex: 0,
            datasetIndex: 4,
            type: "scatter",
            seriesLayoutBy: "row",
            name: '显示/隐藏标志点',
            z: 3,
            symbolSize: 7,
            itemStyle: {
                borderColor: "#ED4C67",
                borderWidth: 2,
                color: '#f56c6c00',
            },
            },
            {
            xAxisIndex: 0,
            datasetIndex: 5,
            type: "scatter",
            seriesLayoutBy: "row",
            name: '显示/隐藏标志点',
            z: 3,
            symbolSize: 7,
            itemStyle: {
                borderColor: "#7d5fff",
                borderWidth: 2,
                color: '#f56c6c00',
            },
            },
            {
            xAxisIndex: 0,
            datasetIndex: 6,
            type: "scatter",
            seriesLayoutBy: "row",
            name: '显示/隐藏标志点',
            z: 3,
            symbolSize: 7,
            itemStyle: {
                borderColor: "#009432",
                borderWidth: 2,
                color: '#f56c6c00',
            },
            },
            {
            xAxisIndex: 0,
            datasetIndex: 7,
            type: "scatter",
            seriesLayoutBy: "row",
            name: '显示/隐藏标志点',
            z: 3,
            symbolSize: 7,
            itemStyle: {
                borderColor: "yellow",
                borderWidth: 2,
                color: '#f56c6c00',
            },
            },
            {
            xAxisIndex: 1,
            datasetIndex: 8,
            type: 'line',
            symbol: "none" /*去掉小圆点*/,
            seriesLayoutBy: "row",
            z: 4,
            itemStyle: {
                normal: {
                lineStyle: {
                    color: "#000" /*折线的颜色*/,
                    width: 3
                },
                color: "#000" /*图例(legend)的颜色,不是图例说明文字的颜色*/,
                },
            },
            }
        ]
        };

    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        this.getTwoInOneData()
        this.initECGChart1(document.getElementById("myChart1"));
        console.log(this.type_detect);
        console.log("this.type_detect");
        if (this.type_detect == 1){
            this.initECGChart(document.getElementById("myChart"));
            this.getConclusion();
        }
        // this.getConclusion();
        // this.initPieChart(document.getElementById("myChart2"));
        this.getinfo()
    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="less" scoped>
p {
  text-indent: 2em;
}

::v-deep .el-tabs__item {
  font-size: 16px !important;
  font-weight: bold
}

::v-deep .el-slider__marks-text {
  width: min-content;
  font-size: 15px;
  color: black;
}

::v-deep .el-slider__runway {
  background-color: #e4e7ed00
}

::v-deep .el-slider__stop {
  height: 8px;
  width: 8px;
  background-color: #e4e7ed00
}

::v-deep .el-slider__bar {
  background-color: #f5f7fa00
}

::v-deep .el-slider__button-wrapper {
  .el-slider__button {
    width: 16px;
    height: 25px;
    border-radius: 0;
    background: rgb(231 239 255);
    border: solid 2px rgb(178 201 251);
  }
}

.image {
  width: calc(95%);
  cursor: pointer;
  margin-bottom: 20px;
  display: inline-block;
  border: solid 2px #576ebb;
  border-radius: 20px;
}

::v-deep .tabs {
  text-align: center;
  top: 50px;
  left: 50px;
  ;
  position: relative;
}

::v-deep .el-tabs {
  color: #000;
  left: 0px;
  top: 0px;
  position: relative;
}


.image_name {
  display: block;
  background: #edecec;
  width: 90%;

}

.image_name_td {
  height: 40px;
  display: table-cell;
  vertical-align: middle;
  font-size: 15px
}

::v-deep .el-dialog__body {
  padding: 0px 20px;
  padding-bottom: 10px;
}
</style>
<style scoped>
.blank {
    border-radius: 4px;
    height: 18px;
}
</style>